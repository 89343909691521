<template>
	<div>
		<div class = "w-30 d-flex ">
			<v-text-field
				ref="search"
				class="m-2"
				v-model.trim="listingSearch"
				placeholder="Search Quotation"
				hide-details
				outlined
				style="max-width: 450px; min-width: 400px"
				@keydown.enter="onEnter"
			>
			<template #prepend-inner>
				<v-icon :color="!isFocused ? 'grey' : undefined" class="ml-1 mr-2"> mdi-magnify </v-icon>
			</template> 
			</v-text-field>
			<v-btn v-if = "listingSearch"
					@click="onClear()"
					color="blue darken-4 white--text"
					class="ml-2 mt-2"
					tile
					depressed
				>
					Clear</v-btn
				>
		</div>
		<div class="custom-page-header-count pt-0 px-0">
			<v-layout class="">
				<template>
					<template v-for="(data, index) in quotationsStatus">
						<v-flex
							:key="index"
							class="py-0"
							:class="{
								'mr-1': index == 0,
								'ml-1': !quotationsStatus[index + 1],
								'mx-1': index != 0 && quotationsStatus[index + 1],
							}"
						>
							<div
								:class="
									'card m-0 top_card card-custom gutter-b border-' + data.color + ' lighten-5 ' + data.color
								"
								v-if="data.type == 'quotation'"
							>
								<!-- @click="getFilteredData(data.value)" -->
								<div class="card-body p-0" @click="getFilteredData(data.value)">
									<div class="mx-2 d-flex justify-space-between py-3">
										<div class="mt-4">
											<inline-svg
												style="width: 60px; height: 40px"
												fill="#baa9a9"
												:src="$assetURL('media/custom-svg/' + data.type + '.svg')"
											/>
										</div>
										<div class="d-flex flex-column justify-space-between">
											<h5 class="w-100 text-dark-55 font-weight-bold m-0" :class="`${data.color}--text`">
												{{ data.text }}
											</h5>
											<!-- v-if="data.text != 'All Quotation'" -->
											<v-avatar
												v-if="data.text != 'All Quotation'"
												style="margin-left: auto"
												class="text-right text-white"
												size="35"
												:color="data.color"
												:text-color="data.textcolor"
												>{{ data.quotation_status_count }}</v-avatar
											>
											<v-avatar
												v-if="data.text == 'All Quotation'"
												style="margin-left: auto"
												class="text-right text-white"
												size="35"
												:color="data.color"
												:text-color="data.textcolor"
												>{{ quotationsCount }}</v-avatar
											>
										</div>
									</div>
								</div>
							</div>
						</v-flex>
					</template>
				</template>
			</v-layout>
		</div>

		<div>
			<v-layout class="min-height-57px">
				<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
					<v-col md="4" class="py-0 my-auto">
						<v-select
							v-model="status"
							class="listing-select"
							hide-details
							item-text="text"
							item-value="value"
							:disabled="pageLoading"
							:items="quotationsStatus"
							label="Status"
							solo
							v-on:change="filterRows"
						>
							<!-- v-on:change="filterRows" -->
							<template v-slot:item="{ item }">
								<v-list-item-action v-if="item.text != 'All Quotation'">
									<v-chip :color="item.color" :text-color="item.textcolor">
										{{ item.quotation_status_count }}
									</v-chip>
								</v-list-item-action>

								<v-list-item-action v-if="item.text == 'All Quotation'">
									<v-chip :color="item.color" :text-color="item.textcolor">
										{{ quotationsCount }}
									</v-chip>
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title v-text="item.text"></v-list-item-title>
									<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
								</v-list-item-content>
							</template>
						</v-select>
					</v-col>
					<!-- <span class="detail-svg-icon mr-2 d-flex align-center">
					
					<inline-svg
						fill="#006fc8"
						:src="$assetURL('media/custom-svg/quotation3.svg')"
						height="20"
						width="20"
					/>
					
					<template>
						<span class="ml-2">Quotation </span>
						<div>
							<v-chip
								v-if="quotationsList.length"
								class="px-2 pr-2 ml-4 mr-6 py-2"
								color="green"
								label
								outlined
								>Total Quotation: {{ quotationsList.length }}
							</v-chip>
						</div>
					</template>
				</span> -->
					<div>
						<v-btn
							@click="goToQuotationCreate(false)"
							color="blue darken-4 white--text"
							class="ml-4"
							tile
							depressed
						>
							<v-icon>mdi-plus</v-icon> Create</v-btn
						>
					</div>
				</v-flex>
			</v-layout>
			<div
				v-if="!pageLoading"
				class="overflow-y budget-listing"
				style="max-height: calc(100vh - 337px); min-height: 200px"
			>
				<v-simple-table class="bt-table listing-table table-head-sticky">
					<!-- {{ quotationsList }} -->
					<thead>
						<tr>
							<th width="10px">Quotation #</th>
							<th>Customer</th>
							<th>Details</th>
							<th>Customer Data</th>
							<th>Contact Person</th>
							<th>Billing Address</th>
							<!-- <th>Customer Address</th> -->
							<th>Service Address</th>
							<!-- <th>Property</th> -->
							<!-- <th>Project</th> -->
							<!-- <th>Rate Opportunity</th> -->
							<!-- <th>Project</th> -->
							<th>Created At</th>
							<!-- <th>Prepared By</th> -->
							<!-- <th>Action</th> -->
						</tr>
					</thead>
					<tbody v-if="quotationsList.length">
						<tr
							class="task_row cursor-pointer"
							v-for="(row, index) in quotationsList"
							:key="index + 1"
							@click="goToQuotationDetail(row)"
						>
							<td width="10px">
								<Chip v-if="row" tooltip tooltip-text="Quotation #" :text="row.barcode" color="cyan"></Chip>
								<div>
									<Chip
										:color="getStatusColor(row.status_value)"
										tooltip
										class="mt-1"
										tooltip-text="Quotation #"
										:text="row.status_value"
									></Chip>
								</div>
							</td>

							<td>
								<Chip
									v-if="row.customer_barcode"
									tooltip
									tooltip-text="customer #"
									:text="row.customer_barcode"
									color="cyan"
								></Chip>
								<em v-else class="text-muted">No Customer</em>
							</td>

							<td>
								<div class="flex-wrap">
									<div class="d-flex">
										<span class="fw-500 mr-1">Title:</span>
										<span class="fw-400 text-truncate d-block">
											<ShowValue :object="row" object-key="title" label="title"> </ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">Reference:</span>
										<span class="fw-400">
											<ShowValue :object="row" object-key="reference" label="reference"></ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">Prepared by:</span>
										<span class="fw-400">
											<ShowValue :object="row" object-key="prepared_by" label="prepared by"></ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500 mr-1">Sales Person:</span>
										<span class="fw-400">
											<ShowValue :object="row" object-key="sales_person" label="sales person"></ShowValue>
										</span>
									</div>
									<div>
										<span class="fw-500">Amount : </span>
										<span class="fw-600">
											<ShowPrice :object="row" object-key="total" label="amount"> </ShowPrice>
										</span>
										<v-icon v-if="row.is_price_changed" color="red" size="12" class="ml-1">mdi-circle</v-icon>
									</div>
								</div>
							</td>
							<td>
								<div>
									<v-icon small left>mdi-home-city-outline</v-icon>
									<ShowValue
										tooltip
										tooltip-text="company name"
										:object="row"
										object-key="customer_company_name"
										label="company name"
									></ShowValue>
								</div>
								<div>
									<v-icon small left>mdi-account</v-icon>
									<ShowValue
										tooltip
										tooltip-text="display name"
										:object="row"
										object-key="customer_name"
										label="display name"
									></ShowValue>
								</div>
								<div>
									<v-icon small left>mdi-email</v-icon>
									<ShowValue
										tooltip
										click-to-copy
										tooltip-text="Email"
										:object="row"
										object-key="customer_email"
										label="email"
									></ShowValue>
								</div>
								<div>
									<v-icon small left>mdi-phone</v-icon>
									<ShowValue
										tooltip
										click-to-copy
										tooltip-text="phone number"
										:object="row"
										object-key="customer_phone"
										label="phone number"
									>
									</ShowValue>
								</div>
							</td>
							<td>
								<div>
									<v-icon small left>mdi-account</v-icon>
									<ShowValue
										tooltip
										tooltip-text="display name"
										:object="row"
										object-key="customer_contact_person_display_name"
										label="display name"
									></ShowValue>
								</div>
								<div>
									<v-icon small left>mdi-email</v-icon>
									<ShowValue
										tooltip
										click-to-copy
										tooltip-text="Email"
										:object="row"
										object-key="customer_contact_person_email"
										label="email"
									></ShowValue>
								</div>
								<div>
									<v-icon small left>mdi-phone</v-icon>
									<ShowValue
										tooltip
										click-to-copy
										tooltip-text="phone number"
										:object="row"
										object-key="customer_contact_person_phone_number"
										label="phone number"
									>
									</ShowValue>
								</div>
							</td>

							<td>
								<div>
									<div v-if="row.customer_shipping_address_line_1">
										<v-icon small left>mdi-account</v-icon>
										<ShowValue
											tooltip
											tooltip-text="Display Name"
											:object="row"
											object-key="customer_shipping_address_name"
											label="display name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-email</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Email"
											:object="row"
											object-key="customer_shipping_address_email"
											label="email"
										>
										</ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-phone</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Phone Number"
											:object="row"
											object-key="customer_shipping_address_phone_number"
											label="phone number"
										></ShowValue>
									</div>
									<div class="d-flex" v-if="row.customer_shipping_address_line_1">
										<div>
											<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
										</div>
										<div>
											<p class="mb-0" v-if="row.customer_shipping_address_line_1">
												{{ row.customer_shipping_address_line_1 }},
												<template v-if="row.customer_shipping_address_line_2">
													{{ row.customer_shipping_address_line_2 }},
												</template>
											</p>
											<p class="mb-0" v-if="row.supplier_billing_unit_number">
												{{ row.supplier_billing_unit_number }},
												<template v-if="row.supplier_billing_country">
													{{ row.supplier_billing_country }},
												</template>
												{{
													row.customer_shipping_address_postal_code
														? row.customer_shipping_address_postal_code
														: null
												}}
											</p>
										</div>
									</div>
									<em v-else class="text-muted">no billing address</em>
								</div>
							</td>

							<!-- <td>
								<div class="m-0" v-if="row.customer_default_address">
									<div class="d-flex m-0">
										<v-icon left small>mdi-account</v-icon>
										<ShowValue
											:object="row.customer_default_address"
											object-key="display_name"
											label="customer_default_address"
										></ShowValue>
									</div>
									<div class="d-flex m-0">
										<v-icon left small>mdi-phone</v-icon>
										<ShowValue
											:object="row.customer_default_address"
											object-key="phone_number"
											label="customer default address"
										></ShowValue>
									</div>

									<div class="m-0">
										<v-icon left small>mdi-email</v-icon>
										<ShowValue :object="row.customer_default_address" object-key="email" label="email">
										</ShowValue>
									</div>
									<div class="d-flex align-start">
										<v-icon class="mt-2" left small>mdi-map-marker-outline</v-icon>
										<div>
											<p class="m-0" v-if="row && row.customer_default_address.address_line_1">
												<ShowValue
													:object="row.customer_default_address"
													object-key="address_line_1"
													label="address line 1"
												>
												</ShowValue>
											</p>
											<p class="m-0" v-if="row && row.customer_default_address.address_line_2">
												<ShowValue
													:object="row.customer_default_address"
													object-key="address_line_2"
													label="address line 2"
												>
												</ShowValue>
											</p>
											<p class="m-0" v-if="row && row.customer_default_address.unit_number">
												<ShowValue :object="row.customer_default_address" object-key="unit_number"> </ShowValue>
											</p>
											<p class="m-0">
												<template v-if="row && row.customer_default_address.address_country">
													<ShowValue
														:object="row.customer_default_address"
														object-key="address_country"
														label="country"
													>
													</ShowValue>
												</template>
												<template class="m-0" v-if="row && row.customer_default_address.address_postal_code">
													&nbsp;<ShowValue
														:object="row.customer_default_address"
														object-key="address_postal_code"
														label="postal code"
													></ShowValue>
												</template>
											</p>
										</div>
									</div>
								</div>
							</td> -->
							<td>
								<div v-if="row.customer_shipping_address_line_1">
									<div>
										<v-icon small left>mdi-account</v-icon>
										<ShowValue
											tooltip
											tooltip-text="Display Name"
											:object="row"
											object-key="customer_shipping_address_name"
											label="display name"
										></ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-email</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Email"
											:object="row"
											object-key="customer_shipping_address_email"
											label="email"
										>
										</ShowValue>
									</div>
									<div>
										<v-icon small left>mdi-phone</v-icon>
										<ShowValue
											tooltip
											click-to-copy
											tooltip-text="Phone Number"
											:object="row"
											object-key="customer_shipping_address_phone_number"
											label="phone number"
										></ShowValue>
									</div>
									<div class="d-flex" v-if="row.customer_shipping_address_line_1">
										<div>
											<v-icon size="16" class="mr-1">mdi-map-marker</v-icon>
										</div>
										<div>
											<p class="mb-0" v-if="row.customer_shipping_address_line_1">
												{{ row.customer_shipping_address_line_1 }},
												<template v-if="row.customer_shipping_address_line_2">
													{{ row.customer_shipping_address_line_2 }},
												</template>
											</p>
											<p class="mb-0" v-if="row.supplier_billing_unit_number">
												{{ row.supplier_billing_unit_number }},
												<template v-if="row.supplier_billing_country">
													{{ row.supplier_billing_country }},
												</template>
												{{
													row.customer_shipping_address_postal_code
														? row.customer_shipping_address_postal_code
														: null
												}}
											</p>
										</div>
									</div>
								</div>
								<em v-else class="text-muted">no service address</em>
							</td>
							<!-- <td class="text-capitalize truncate-text">
							<ShowValue :object="row" object-key="property" label="row.property"></ShowValue>
						</td> -->
							<!-- <td>
							<Chip tooltip tooltip-text="project #" :text="row.project" color="cyan"></Chip>
						</td> -->
							<!-- <td>
							<v-rating
								v-model="row.rating"
								v-if="row.rating > 0"
								background-color="gray"
								color="orange accent-4"
								dense
								half-increments
								readonly
								hover
								size="24"
							></v-rating>
							<em v-else class="text-muted">No rating opportunity</em>
						</td> -->
							<!-- <td>
								

								<div class="text-truncate">
									<span class="fw-500">Name : </span>
									<ShowValue
										truncate
										:object="row"
										object-key="project_name"
										label="project name"
									></ShowValue>
								</div>
								<div class="d-flex">
									<span>
										<span class="fw-500">Price : </span>
										<span class="fw-600">
											<ShowPrice :object="row" object-key="project_price" label="project cost"></ShowPrice>
										</span>
									</span>
									<v-spacer></v-spacer>
									<v-tooltip top v-if="false">
										<template v-slot:activator="{ on, attrs }">
											<v-icon
												v-on="on"
												v-bind="attrs"
												class="text-right"
												size="16"
												:color="getStatusColor(row.project_status_value)"
												>mdi-circle</v-icon
											>
										</template>
										<span
											>Project Status -
											<span class="text-uppercase">{{ row.project_status_value }}</span></span
										>
									</v-tooltip>
								</div>
								<div>
									<span class="fw-500">Start : </span>
									<template v-if="row.project_start_date">
										{{ formatDate(row.project_start_date) }}
									</template>
									<template v-else>
										<em class="text-muted">no start date</em>
									</template>
								</div>
								<div>
									<span class="fw-500">End : </span>
									<template v-if="row.project_end_date">
										{{ formatDate(row.project_end_date) }}
									</template>
									<template v-else>
										<em class="text-muted">no end date</em>
									</template>
								</div>
								<div>
															<span class="fw-500">Status : </span>
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<v-chip
																		:color="getStatusColor(row.project_status_value)"
																		label
																		small
																		outlined
																		v-on="on"
																		v-bind="attrs"
																		class="white--text"
																	>
																		<span class="text-uppercase">
																			{{ row.project_status_value }}
																		</span>
																	</v-chip>
																</template>
																<span>Project Status</span>
															</v-tooltip>
														</div>
							</td> -->

							<td>
								<!-- <Chip tooltip tooltip-text="Reference #" :text="row.reference" color="cyan"></Chip> -->
								<div>
									<Chip
										tooltip
										tooltip-text="Created By"
										small
										:text="row.created_by"
										color="blue darken-4 white--text"
										class="mb-1"
									></Chip>
								</div>

								<TableDateTime
									small
									v-if="row.created_at"
									:human-format="row.added_at"
									:system-format="row.created_at"
								></TableDateTime>
							</td>

							<!-- <td>
								<ShowValue :object="row" object-key="prepared_by" label="Prepared By"></ShowValue>
							</td> -->
							<!-- <td class="simple-table-td">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										@click.prevent.stop="openDeleteConfirmationDialog(row)"
										class="mx-2"
										color="red lighten-1"
										v-bind="attrs"
										v-on="on"
										dark
										fab
										x-small
									>
										<v-icon small dark> mdi-trash-can-outline</v-icon>
									</v-btn>
								</template>
								<span>Delete Quotation</span>
							</v-tooltip>
						</td> -->
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no quotation at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
				<v-row class="my-2">
				<v-col md="6" class="my-auto">
					<p class="m-0">{{ showingStringNew }}</p>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="totalPageNew"
						total-visible="9"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row>
			</div>
			<div v-else class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
			<DeleteTemplate
				type="Payment"
				v-on:success="deleteQuotation"
				v-on:close="(deleteDialog = false), (uuid = null)"
				:delete-dialog="deleteDialog"
				:deleteText="uuid"
				:deleteByUrl="false"
			>
			</DeleteTemplate>
		</div>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue.vue";
import { mapGetters } from "vuex";
import TableDateTime from "@/view/components/TableDateTime";
import {  SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
//import ShowTextAreaValue from "@/view/components/ShowTextAreaValue.vue";
import DeleteTemplate from "@/view/components/DeleteTemplate.vue";
import Chip from "@/view/components/Chip";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import ShowPrice from "@/view/components/ShowPrice";
import { getQuotationByProject, deleteQuotation } from "@/core/lib/project.lib";
import { EventBus } from "@/core/event-bus/event.bus";
export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	components: {
		ShowValue,
		ShowPrice,
		Chip,
		//ShowTextAreaValue,
		DeleteTemplate,
		TableDateTime,
	},
	data() {
		return {
			deleteDialog: false,
			listingSearch: null,
			status: 'all',
			page: 1,
			pageLoading: false,
			uuid: null,
			quotationsList: [],
			quotationsStatus: [],
			quotationsCount: 0,
			showingStringNew: null,
			currentPageNew: null,
			totalPageNew: null,
		};
	},
	props: {
		relationTypeId: {
			type: Number,
			default: 0,
		},
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {

updatePagination(param) {
	console.log(param,"param");
			if (this.currentPageNew == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.filterRows();
			this.$nextTick(() => {
				this.$emit("reload:content", true);
			});
		},



getFilteredData(value){
			EventBus.$emit("reload:project", value);
			// this.filterRows();
		},


		filterRows() {
			this.$router
				.replace({
					query: {
						...this.$route.query,
						...this.btxQuery,
						page: this.currentPage,
						status: this.status,
						search: this.listingSearch || undefined,
						t: new Date().getTime(),
					},
				})
				.then(() => {
					console.log(this.$route.query.search);
					this.getQuotationList();
					//this.getListing();
				});
		},
		onClear(){
			this.listingSearch = '';
			this.filterRows();
		},
		onEnter() {
			this.filterRows();
		},
		openDeleteConfirmationDialog(row) {
			this.deleteDialog = true;
			this.uuid = row.uuid;
		},
		async deleteQuotation() {
			this.deleteDialog = false;
			try {
				this.pageLoading = true;
				await deleteQuotation(this.uuid);
				this.uuid = null;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success ! Quotation has been deleted.` },
				]);
				await this.getQuotationList();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},

		goToQuotationCreate() {
			this.$router
				.push({
					name: "quotation-create",
					query: { project: this.$props.project.id, customer: this.$props.project.contact },
				})
				.catch(() => {});
		},

		goToQuotationDetail(quotation) {
			this.$router
				.push({
					name: "quotation-detail",
					params: { uuid: quotation.uuid },
				})
				.catch(() => {});
		},
		async getQuotationList() {
			try {
				this.pageLoading = true;
				const { quotations, quotation_status, quotations_count,showing_string,current_page,total_page } = await getQuotationByProject(
					this.$route.params.uuid,
					{ status: this.$route.query.status,
					 search: this.$route.query.search,
					 page: this.$route.query.page
					 }
				);
				this.quotationsList = quotations;
				this.quotationsStatus = quotation_status;
				this.quotationsCount = quotations_count;
				this.showingStringNew = showing_string;
				this.currentPageNew = current_page;
				this.totalPageNew = total_page;
				
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"currentPage",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
	},
beforeDestroy() {
		EventBus.$off("reload:project");
	},
	async mounted() {
		await this.getQuotationList();


EventBus.$on("reload:project", (value) => {
			this.status = value;
			this.filterRows();
			
		});


	},
};
</script>
